<template>
  <Layout>
    <div class="app-ad-pop">
      <div class="tool">
        <a-form layout="inline">
          <a-button @click="add" type="primary">
            <a-icon type="file-add" /> 新建弹窗广告
          </a-button>
        </a-form>
      </div>
      <div class="table-wrap">
        <a-table :columns="columns" :data-source="list" :pagination="false">
          <span slot="startTime" slot-scope="time">{{
            time | formatDate
          }}</span>
          <span slot="endTime" slot-scope="time">{{ time | formatDate }}</span>
          <span slot="action" slot-scope="_, record">
            <router-link :to="{ name: 'AdPopEdit', query: { id: record.id } }">
              编辑
            </router-link>
            <a-divider type="vertical" />
            <a @click="toggleActive(record.id)">{{
              record.active ? "关闭" : "启用"
            }}</a>
            <a-divider type="vertical" />
            <a style="color:#f5222d" @click="del(record.id)">删除</a>
          </span>
        </a-table>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

const AdPopSvc = require("@/service/ad-pop");

const columns = [
  {
    title: "名称",
    dataIndex: "name",
    key: "name"
  },

  {
    title: "开始时间",
    align: "center",
    dataIndex: "startTime",
    key: "startTime",
    width: 160,
    scopedSlots: { customRender: "startTime" }
  },
  {
    title: "结束时间",
    align: "center",
    dataIndex: "endTime",
    key: "endTime",
    width: 160,
    scopedSlots: { customRender: "endTime" }
  },
  {
    title: "状态",
    align: "center",
    dataIndex: "status",
    key: "status",
    width: 100
  },
  {
    title: "操作",
    key: "action",
    width: 220,
    scopedSlots: { customRender: "action" }
  }
];

export default {
  name: "AdPopList",

  data() {
    return {
      columns,

      list: []
    };
  },

  computed: {},

  methods: {
    add() {
      this.$router.push({ name: "AdPopEdit" });
    },

    async toggleActive(id) {
      try {
        await AdPopSvc.updateStatus(id);

        await this.fetch();
      } catch (err) {
        console.error(err);
        this.$message.error("切换状态失败，错误：" + err.message);
      }
    },

    async del(id) {
      this.$confirm({
        title: "操作提示",
        content: "确认删除此弹窗吗？",

        onOk: () => {
          this._delete(id);
        },
        onCancel() {}
      });
    },

    async _delete(id) {
      try {
        await AdPopSvc.del(id);

        await this.fetch();
      } catch (err) {
        console.error(err);
        this.$message.error("删除失败，错误：" + err.message);
      }
    },

    async fetch() {
      try {
        this.list = await AdPopSvc.list();
      } catch (err) {
        console.error(err);
        this.$message.error("加载数据出错，错误：" + err.message);
      }
    }
  },

  async mounted() {
    await this.fetch();
  },

  components: {
    Layout
  }
};
</script>

<style lang="scss">
.app-ad-pop {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  background: #f0f2f5;
  padding: 24px 24px 0;

  .tool {
    padding: 24px 24px;
    background: #fff;
    display: flex;
  }
  .table-wrap {
    background: #fff;
  }
}
</style>
